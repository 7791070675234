import axios from "axios";
import qs from "qs";
const brand = {
  taskInfo() {
    //礼品数据
    return axios.get("/api/ppy/usercenter/luckyCard/taskInfo");
  },
  orderSubmit() {
    return axios.post("/api/ppy/usercenter/luckyCard/drawCard");
  },
  cardClick(type) {
    return axios.get("/api/ppy/usercenter/luckyCard/cardClick",{params:{
      type
    }});
  },
  lotty() {
    return axios.get("/api/ppy/usercenter/luckyCard/simulation/lotty");
  },
  simulationinfo() {
    return axios.get("/api/ppy/usercenter/luckyCard/simulation/info");
  },
  cardLotty(drawInfoId) {
    return axios.post("/api/ppy/usercenter/luckyCard/cardLotty",qs.stringify({ drawInfoId },{indices:false}));
  },
  myExchangeRecords(page) {
    return axios.get("/api/ppy/usercenter/luckyCard/myExchangeRecords",{params:{
      page,
      pageSize:20
    }});
  },
  marquee() {
    return axios.get("/api/ppy/usercenter/luckyCard/marquee");
  },
  ///api/luckyCard/exchange
  luckyCardexchange(drawInfoId) {
    return axios.post(
      "/api/ppy/usercenter/luckyCard/exchange",
      qs.stringify({ drawInfoId },{indices:false})
    );
  },
  myCards() {
    //礼品数据
    return axios.get("/api/ppy/usercenter/luckyCard/myCards");
  },
};
export default brand;
