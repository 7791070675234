<template>
  <div class="content">
    <div class="content-head">
    <p class="headtime">活动时间：{{time(beginTime)}}-{{time(endTime)}}</p>
    <div class="horselamp"  :style="`width:${redswidth*2.2}px;`">
      <div  :style="`margin-left:${-gotime}px;`"  >
        <div v-for="(item,index) in horselamp" :key="index" ref="reds">
          <img :src="item.avatar?item.avatar:'https://ppyos.xijiuyou.com/imgs/invite/default_avatar.png'" alt="">
          <span>{{item.msg}}</span>
        </div>
      </div>
      <div>
        <div v-for="(item,index) in horselamp" :key="index">
          <img :src="item.avatar?item.avatar:'https://ppyos.xijiuyou.com/imgs/invite/default_avatar.png'" alt="">
          <span>{{item.msg}}</span>
        </div>
      </div>
    </div>
    <div class="guize">
      <div @click="guize"></div>
      <div @click="Winning = true;"></div>
      <div @click="brand"></div>
    </div>
    <div class="majiang">
      <img src="https://ppyos.xijiuyou.com/2401/mj/mj-pb.png" alt=""/>
      <img src="https://ppyos.xijiuyou.com/2401/mj/mj-pb.png" alt=""/>
      <img src="https://ppyos.xijiuyou.com/2401/mj/mj-pb.png" alt=""/>
    </div>
    <div class="mopaibtn">
      <div>
        <img @click="Touch" src="https://ppyos.xijiuyou.com/202301/mj-mp-btn.png" alt="" />
        <div v-if="availableChance>0">{{availableChance}}</div>
      </div>
      <p>剩余{{availableChance}}次，做任务可加次数</p>
    </div>
    </div>
    <div class="task">
      <img class="headimg" src="https://ppyos.xijiuyou.com/2401/task-txt-img.png" alt="">
      <div class="list">
        <div class="list-content" v-for="(item ,index) in tasks" :key="index"  :style="item.type==6?item.status==1?'background: linear-gradient(270deg, #FFE234 0%, #6AE476 55%, #40C5FF 100%);opacity: 0.5;':'background: linear-gradient(270deg, #FFE234 0%, #6AE476 55%, #40C5FF 100%);':''">
          <div class="list-content-left">
            <img :src="item.icon" alt=""/>
            <div>
              <p :style="item.type==6?'color:#ffffff;':''">{{ item.title }} </p>
              <p :style="item.type==6?'color:#ffffff;':''">{{ item.note }} <img src="https://ppyos.xijiuyou.com/202301/mj-bg-small.png" alt=""></p>
            </div>
          </div>
          <!--  -->
          <button @click="gotasks(item)" :style="item.status==1?item.type!=6?'color:#ffffff;background: linear-gradient(270deg, rgba(255, 139, 69, 0.2) 0%, rgba(255, 26, 26, 0.2) 100%);':'':item.type==6?'':'color:#ffffff;background: linear-gradient(270deg, #FF8B45 0%, #FF1A1A 100%);'">{{item.status==0? item.type==6?'去邀请':item.type==5?'去做任务':item.type==2?'去签到':'去玩':'已完成'}}</button>
        </div>
      </div>
    </div>
    <xwpopup :isshowpopup="newpeople" @close="closenewpeople()" :hiddenClose="false">
      <div class="newpeople">
        <img src="https://ppyos.xijiuyou.com/2401/newer-gift.png" alt="">
        <p>额外赠送你 <span>{{newbieSendChance}}次</span> 摸牌机会</p>
        <img @click="closenewpeople()" src="https://ppyos.xijiuyou.com/202301/sx-btn.png" alt="">
      </div>
    </xwpopup>
    <xwpopup :isshowpopup="Winning" @close="closeWinning()" :hiddenClose="true">
      <div class="Winnings">
        <img class="Winningstitle" src="https://ppyos.xijiuyou.com/202301/zj-mj-txt.png" alt="">
        <div class="Winningscontent">
        <div class="titles">
          <p>全牌型(8888元）</p>
          <img src="https://ppyos.xijiuyou.com/2401/mj-example-3.png" alt="">
        </div>
        <div class="titles">
          <p>东南西北(18.8元）</p>
          <div>
            <img src="https://ppyos.xijiuyou.com/2401/mj-dong.png" alt=""/>
            <img src="https://ppyos.xijiuyou.com/2401/mj-nan.png" alt=""/>
            <img src="https://ppyos.xijiuyou.com/2401/mj-xi.png" alt=""/>
            <img src="https://ppyos.xijiuyou.com/2401/mj-bei.png" alt=""/>
          </div>
        </div>
        <div class="Winningtitles">
          <div>
            <p>发财(3.8元）</p>
            <img src="https://ppyos.xijiuyou.com/2401/mj-fa.png" alt="">
          </div>
          <div>
            <p>红中(1.8元）</p>
            <img src="https://ppyos.xijiuyou.com/2401/mj-zhong.png" alt="">
          </div>
        </div>
        <div class="titles">
          <p>三张相同牌(限万、筒、条牌)(0.18元)</p>
          <img class="titlesimgs" src="https://ppyos.xijiuyou.com/2401/mj-example-1.png" alt="">
        </div>
        <div class="titles">
          <p>三张同组牌(限万、筒、条牌)(0.18元)</p>
          <img class="titlesimgs1" src="https://ppyos.xijiuyou.com/2401/mj-example-2.png" alt="">
        </div>
        </div>
        <img @click="closeWinning" class="guanb" src="https://ppyos.xijiuyou.com/202301/close-icon-img.png" alt="">
      </div>
    </xwpopup>
      <xwpopup :isshowpopup="guaj" @close="closephones()" :hiddenClose="false">
          <div class="container" id="top">
              <div class="award_box">
                  <img class="maj" :src="ordercontent.img" alt="">
                  <img class="smallbanner" src="https://ppyos.xijiuyou.com/202301/red-small-banner.png" alt="">
                  <p class="title">恭喜抽到“{{ordercontent.desc}}”，还可刮奖哦~</p>
                  <div class="award" v-if="showPrize" :style="awardText.isAward?'':'background:#ffffff;'">
                      <div class="title" :style="awardText.isAward?'font-size: 14px;':''"> {{ awardText.isAward?'恭喜刮到':awardText.awardText }} <span v-if="awardText.isAward">{{awardText.awardText}}元</span></div>
                  </div>
                  <img class="canvas" v-show="!isScratch" @click="touchstart" src="https://ppyos.xijiuyou.com/202301/gg-card.png" alt="">
                  <!-- <canvas
                      id="c1"
                      class="canvas"
                      @touchmove="touchmove"
                      @touchstart="touchstart"
                      @touchend="touchend"
                      
                  ></canvas> -->
              </div>
          </div>
      </xwpopup>
      <xwpopup  :isshowpopup="fenimg" @close="closefenimg()" :hiddenClose="true">
          <div class="fenimg">
              <img :src="imgqr.imgblob" alt="" />
              <img src="https://ppyos.xijiuyou.com/2401/mj-invite-friend-txt.png" alt="">
              <img @click="closefenimg" src="https://ppyos.xijiuyou.com/202301/close-icon-new.png" alt="">
              <div class="fenimgbottom">
                  <div @click="wechatorqq(1)">
                      <img src="https://ppyos.xijiuyou.com/202212/icon-wechat.png" alt="">
                      <p>微信分享</p>
                  </div>
                  <div @click="wechatorqq(2)">
                      <img src="https://ppyos.xijiuyou.com/202212/icon-qq.png" alt="">
                      <p>QQ分享</p>
                  </div>
              </div>
          </div>
      </xwpopup>
      <xwpopup  :isshowpopup="succnewtime" @close="closesuccnewtime()" :hiddenClose="false">
          <div class="succnewtime">
              <img src="https://ppyos.xijiuyou.com/2401/mj-top-img.png" alt="">
              <p v-if="newtime < new Date(beginTime).getTime()/1000 ">活动于2月5日开始 <br/> 敬请期待</p>
              <p v-else-if="newtime > new Date(endTime).getTime()/1000">本活动已结束 <br/> 感谢关注</p>
              <div @click="closesuccnewtime">好的</div>
          </div>
      </xwpopup>


      <div class="bill" ref="bill">
          <div class="qrcode">
              <div id="qrcode" ref="qrcode"></div>
              <p>好友<span>送你3次</span>自摸机会</p>
          </div>
          <img crossorigin="anonymous" class="share-bg" src="https://bubblefish.oss-cn-hangzhou.aliyuncs.com/2401/invite-bg-img.png" alt="">
      </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import QRCode from "qrcodejs2";
import { Toast } from "vant";
import brand from "../../api/brand";
import home from "../../api/home";
import xwpopup from "../../components/Popupbox.vue";
export default {
  components: {
  xwpopup,
},
data() {
  return {
    c1: "", //画布
    ctx: "", //画笔
    ismousedown: false, //标志用户是否按下鼠标或开始触摸
    fontem: "", // 获取html字体大小
    isScratch: false, // 是否刮过卡
    showPrize: false ,// 显示奖品
    guaj:false,
    gotime:0,
    redswidth:0,
    horselamp:[],
    Winning:false,
    imgqr:{},
    fenimg:false,
    tasks:[],
    newpeople:false,
    availableChance:0,
    newbieSendChance:0,
    beginTime:"",
    endTime:"",
    ordercontent:{},
    succnewtime:false,
    newtime:0,
    awardText:{},
    shave:true,
  };
},
mounted() {
  this.marquee();
  this.taskInfo();
  this.myInviteLink();
  
  this.fontem = parseInt(
    window.getComputedStyle(document.documentElement, null)["font-size"]
  );
  //这是为了不同分辨率上配合@media自动调节刮的宽度
  
  
},
destroyed(){
  const that = this;
  clearInterval(that.times);
},
methods: {
  myInviteLink(){
    home.myInviteLink().then(res=>{
      this.qrcode(res.data.code);
    })
  },
  closesuccnewtime(){
    this.succnewtime = !this.succnewtime;
  },
  Touch(){
    // if(this.availableChance === 0){
    //   Toast("暂无摸牌次数，请前往完成任务");
    //   return false;
    // }
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
    });
    brand.orderSubmit().then(res=>{
      Toast.clear();
      this.ordercontent = res.data;
      this.guaj = true;
      this.isScratch=false;
      this.showPrize=false;
      const tome = setTimeout(()=>{
        // this.c1 = document.getElementById("c1");
        // this.c1.width = 205;
        // this.c1.height = 78;
        // this.ctx = this.c1.getContext("2d");
        this.marquee();
        this.taskInfo();
        // this.initCanvas();
        clearTimeout(tome)
      },200)
    })
  },
  time(item) {
      if(item){
        return dayjs(item).format("MM月DD日");
      }else{
        return "";
      }
      
    },
  closenewpeople(){
    this.newpeople = !this.newpeople;
  },
  gotasks(item){
    if(item.type === 6){
      this.fenimg = true;
    }else{
      if(item.status === 0){
        try {
          window.location.href = item.url;
        } catch (e) {
          Toast("您当前app版本过低请更新app");
        }
      }
    }
  },
  taskInfo(){
    brand.taskInfo().then(res=>{
      this.newtime = res.timestamp/1000;
      this.availableChance = res.data.availableChance;
      this.tasks = res.data.tasks;
      this.newpeople = res.data.isShowNewbieDialog;
      this.newbieSendChance = res.data.newbieSendChance;
      this.endTime = res.data.endTime;
      this.beginTime = res.data.beginTime;
      if(this.newtime >= dayjs(this.beginTime).valueOf()/1000 && this.newtime <= dayjs(this.endTime).valueOf()/1000){
          this.succnewtime = false;
      }else{
          this.succnewtime = true;
      }
    })
  },
  brand(){
    this.$router.push({
      name: "brand",
    })
  },
  wechatorqq(index){
        if(index==1){
            try {
                window.android.shareToWX(
                    0,2,"","",this.imgqr.img,1,""
                );
            } catch (e) {
                Toast("您当前app版本过低请更新app");
            }
        }else{
            try {
                window.android.shareToQQ(
                    0,2,"","",this.imgqr.img,1,""
                );
            } catch (e) {
                Toast("您当前app版本过低请更新app");
            }
        }
        this.fenimg = false;
    },
  closefenimg(){
    this.fenimg = !this.fenimg;
  },
  qrcode(code){
        this.$refs['qrcode'].innerHTML = '';
        const router = window.location.href.replace('majiang', 'majiangs')
        new QRCode("qrcode", {
            width: 187, // 二维码宽度，单位像素
            height: 187, // 二维码高度，单位像素
            text: router+"?code="+code, // 生成二维码的链接
        });
        const time = setTimeout(()=>{
            this.htmlcanvas();
            clearTimeout(time);
        },300)
    },
    async htmlcanvas(){
        const that = this;
        const bill = await html2canvas(this.$refs.bill, { useCORS: true });
        bill.toBlob(
            function (blob) {
                that.imgqr={
                    imgblob: URL.createObjectURL(blob),
                    img: bill.toDataURL("image/png"),
                };
            },
            "image/jpeg",
            0.95
        );
    },
  closeWinning(){
    this.Winning = !this.Winning;
  },
  guize(){
    this.$router.push({
      name: "mjrule",
    });
  },
  gohorse(){
    const that = this;
    that.$nextTick(()=>{
      if(this.horselamp.length<1){
        return false;
      }
      that.$refs.reds.map(res=>{
        that.redswidth = that.redswidth+res.clientWidth;
      })
      that.redswidth = that.redswidth+(that.horselamp.length*80)+40
      that.times = setInterval(()=>{
        if(that.gotime>= that.redswidth){
          that.gotime = 0
        }else{
          that.gotime++
        }
      },10)
    })
  },
  marquee(){
    brand.marquee().then(res=>{
      this.horselamp = res.data;
      this.gohorse();
    })
  },
  closephones(){
      this.guaj = !this.guaj;
      this.shave = true;
  },
  // 画刮刮卡
  // initCanvas() {
  //   this.ctx.globalCompositeOperation = "source-over";
  //   // var image = new Image();
  //   // image.crossOrigin = "anonymous";
  //   // image.src = "https://ppyos.xijiuyou.com/202301/gg-card.png";
  //   // this.ctx.drawImage(image, 0,0,205,78);
  //   this.ctx.fillStyle = "#e5e5e5";
  //   this.ctx.fillRect(0, 0, this.c1.clientWidth, this.c1.clientHeight);
  //   this.ctx.fill();
  //   this.ctx.font = "Bold 16px Arial";
  //   this.ctx.textAlign = "center";
  //   this.ctx.fillStyle = "#a0a0a0";
  //   this.ctx.fillText("刮一刮", this.c1.width / 2, 47);
  //   //有些老的手机自带浏览器不支持destination-out,下面的代码中有修复的方法
  //   this.ctx.globalCompositeOperation = "destination-out";
  // },
  touchstart(e) {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
    });
    if(this.shave){
      this.shave = false;
      brand.cardLotty(this.ordercontent.drawInfoId).then(res=>{
        Toast.clear();
        this.awardText = res.data;
        this.isScratch = true;
        this.showPrize = true
      })
    }
    
    e.preventDefault();
    this.ismousedown = true;
  },
  // 操作刮卡
  touchend(e) {
    
    sessionStorage.setItem('isScratch',true)
    e.preventDefault();
    //得到canvas的全部数据
    var a = this.ctx.getImageData(0, 0, 205, 78);
    var j = 0;
    for (var i = 3; i < a.data.length; i += 4) {
      if (a.data[i] == 0) j++;
    }
    //当被刮开的区域等于一半时，则可以开始处理结果
    if (j >= a.data.length / 8) {
      this.isScratch = true;
    }
    this.ismousedown = false;
  },
  // touchmove(e) {
    
  //   this.showPrize = true
  //   e.preventDefault();
  //   if (this.ismousedown) {
  //     if (e.changedTouches) {
  //       e = e.changedTouches[e.changedTouches.length - 1];
  //     }
  //     var topY = document.getElementById("top").offsetTop;
  //     var oX = this.c1.offsetLeft,
  //       oY = this.c1.offsetTop + topY;
  //     var x = (e.clientX + document.body.scrollLeft || e.pageX) - oX || 0,
  //       y = (e.clientY + document.body.scrollTop || e.pageY) - oY || 0;
  //     //画360度的弧线，就是一个圆，因为设置了ctx.globalCompositeOperation = 'destination-out';
  //     //画出来是透明的
  //     this.ctx.beginPath();
  //     this.ctx.arc(x, y, this.fontem * 0.5, 0, Math.PI * 2, true); // 调整画笔的大小
  //     //下面3行代码是为了修复部分手机浏览器不支持destination-out
  //     //我也不是很清楚这样做的原理是什么
  //     // this.c1.style.display = 'none';
  //     // this.c1.offsetHeight;
  //     // this.c1.style.display = 'inherit';
  //     this.ctx.fill();
  //   }
  // }
}
};
</script>
<style lang="less" scoped>
.container {
width: 100%;
padding:180px 0 0;
display: flex;
align-items: center;
justify-content: space-around;
position: relative;
.award_box {
  height: 245px;
  width: 263px;
  margin: 0px auto;
  background: url("https://ppyos.xijiuyou.com/2401/mj-gift-big-img.png") no-repeat;
  background-size: 100%;
  position: relative;
  z-index: 1;
  .title{
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFF4D2;
  }
  .smallbanner{
    display: block;
    width: 143px;
    margin: -4px auto 7px;
  }
  .maj{
    display: block;
    width: 78px;
    margin: -30px auto 0;
    position: relative;
    z-index: 1;
  }
  .award {
    width: 205px;
    height: 78px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("https://ppyos.xijiuyou.com/202301/gg-card-result.png") no-repeat;
    background-size: 100%;
    border-radius: 15px;
    margin-top: 14px;
    .title {
      color: rgba(3, 45, 97, 1);
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #444444;
      overflow: hidden;
      padding: 0 10px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      span {
        color: #FF3500;
      }
    }
  }
  .canvas {
    position: relative;
    width: 205px;
    height: 78px;
    border-radius: 15px;
    margin-top: 14px;
    z-index: 10;
  }
}
}
.content{
  width: 100vw;
  
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  background: linear-gradient(270deg, #FFF3F5 0%, #FFEBDF 100%);
  .content-head{
    background: url("https://ppyos.xijiuyou.com/2401/mj-bg-img-8888.png") no-repeat;
    background-size: 100%;
    position: relative;
    padding-bottom: 66px;
    z-index: 1;
  }
  .headtime{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    padding-top: 3px;
    position: relative;
    z-index: 2;
  }
  .guize{
    width: 33px;
    height: 230px;
    background: url("https://ppyos.xijiuyou.com/202301/rule-long-img.png") no-repeat;
    background-size: 100%;
    position: absolute;
    right: 7px;
    top: 0;
    z-index: 11;
    padding-top: 35px;
    div:nth-child(1){
      width: 100%;
      height: 50px;
    }
    div:nth-child(2){
      width: 100%;
      height: 90px;
    }
    div:nth-child(3){
      width: 100%;
      height: 60px;
    }
  }
  .majiang{
    display: flex;
    justify-content: center;
    margin-top: 220px;
    img{
      width: 70px;
      height: 104px;
      margin: 0 7px;
    }
  }
  .mopaibtn{
    margin-top: 30px;
    text-align: center;
    div{
      position: relative;
      width: 157px;
      height: 52px;
      margin: 0 auto;
      img{
        width: 100%;
      }
      div{
        position: absolute;
        width: 22px;
        height: 22px;
        background: linear-gradient(180deg, #FFF5CE 0%, #FFE6A0 100%);
        font-size: 15px;
        font-family: DINOffcPro-Bold, DINOffcPro;
        font-weight: bold;
        color: #FE8C12;
        border-radius: 50%;
        right: -5px;
        top: -5px;
        padding-top: 2.5px;
      }
    }
    p{
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      margin-top: 9px;
    }
  }
  .task{
    width: 100%;
    border-left: 2px solid rgba(255, 233, 136, 0.64);
    border-right: 2px solid rgba(255, 233, 136, 0.64);
    background: linear-gradient(270deg, #FFF8F3 0%, #FFEEDF 100%);
    padding-bottom: 16px;
    border-bottom:0;
    padding-top: 24px;
    text-align: center;
    margin-top: -20px;
    .headimg{
      width: 105px;
      height: 26px;
      margin-bottom: 11px;
    }
    .list{
      padding: 0 10px;
      .list-content{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 16px 13px;
        background: #ffffff;
        border-radius: 15px;
        align-items: center;
        margin-bottom: 10px;
        .list-content-left{
          display: flex;
          align-items: center;
          text-align: left;
          div{
            p{
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #6F0200;
            }
            p:nth-child(2){
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              display: flex;
              align-items: center;
            }
            img{
              width: 11px;
              height: 15px;
              margin: 0;
              margin-left: 3px;
            }
          }
        }
        button{
            width: 70px;
            height: 30px;
            background: #fff;
            border-radius: 15px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #9C1000;
            border:0;
          }
        img{
          width: 32px;
          height: 32px;
          margin-right: 9px;
        }
      }
    }
  }
}


.horselamp{
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 10; 
  padding-top: 31px;
  display: flex;
}
.horselamp div div img{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
  float: left;
  margin: 4px;
}
.horselamp div div {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  display: inline-block;
  height: 32px;
  background: rgba(240, 161, 104, 0.78);
  border-radius: 16px;
  box-sizing: border-box;
  margin-right:80px;
}
.horselamp div{
  display: block;
}
.horselamp div div span{
  line-height: 32px;
  height: 32px;
  overflow: hidden;
  width: calc(100% - 32px);
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
  display: flex;
  align-items: center;
  padding-right: 4px;
}
.Winnings{
  position:fixed;
  height: 70vh;
  width: 100%;
  background: linear-gradient(270deg, #FFF3F5 0%, #FFEBDF 100%);
  border-radius: 20px 20px 0px 0px;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 18px 8px 10px;
  overflow-y: hidden;
  .Winningscontent{
    height: 60vh;
    overflow-y: auto;
  }
  .guanb{
    position: absolute;
    right: 22px;
    top: 22px;
    width: 12px;
    height: 12px;
  }
  .Winningstitle{
    width: 116px;
    margin-bottom: 24px;
  }
  .titles{
    width: 100%;
    background: linear-gradient(360deg, #FFC5C5 0%, #FFE0D3 99%);
    border-radius: 10px;
    padding: 12px 0;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FF3500;
    margin-bottom: 8px;
    .titlesimgs{
      width: calc(100% - 18px);
    }
    .titlesimgs1{
      width: calc(100% - 35px);
    }
    p{
      margin-bottom: 14px;
    }
    img{
      width: calc(100% - 68px);
    }
    div{
      display: flex;
      justify-content:center;
      img{
        width: 58px;
        height: 86px;
        margin: 0 5px;
      }
    }
  }
  .Winningtitles{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    div{
      width: calc(50% - 5px);
      background: linear-gradient(360deg, #FFC5C5 0%, #FFE0D3 99%);
      border-radius: 10px;
      padding: 12px 0;
      p{
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FF3500;
        margin-bottom: 14px;
      }
      img{
        width: 58px;
      }
    }
  }
}
.bill{
        width: 375px;
        height: 610px;
        position: fixed;
        left: -10000px;
        top: 0px;
        padding-top: 11px;
        .share-bg{
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
        .qrcode{
            position: relative;
            z-index: 10;
            margin: 180px auto 0;
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            width: 225px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 19px;
            font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #444444;
          padding-bottom: 19px;
          box-sizing: border-box;
          span{
            color: rgba(0, 157, 231, 1);
          }
          p{
            margin-top: 14px;
          }
        }
    }
    #qrcode{
        width: 187px;
        height: 187px;
    }
    .fenimg{
        width: calc(100% - 80px);
        height: 100vh;
        text-align: center;
        padding-top: 20px;
        position: relative;
        margin: 0 auto;
        img:nth-child(1){
            width: 100%;
            display: block;
            margin: 0 auto;
        }
        img:nth-child(2){
            width: calc(100% - 40px);
            margin-top: 26px;
        }
        img:nth-child(3){
          width: 26px;
          height: 26px;
          position: absolute;
          right: -13px;
          top: 10px;
        }
        .fenimgbottom{
            position: absolute;
            bottom: 0;
            left: -40px;
            width: 100vw;
            height: 118px;
            background: #FFFFFF;
            display: flex;
            justify-content: space-around;
            padding-top:20px;
            div{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #444444;
                img{
                    width: 54px;
                    height: 54px;
                }
            }
        }
    }
.newpeople{
  width: 275px;
  background: #FFFFFF;
  border-radius: 20px;
  margin-top: 150px;
  padding-bottom: 30px;
  img:nth-child(1){
    width: 100%;
    margin-top: -60px;
  }
  p{
    margin-top: 16px;
    margin-bottom: 34px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    span{
      font-size: 24px;
      color: rgba(255, 53, 0, 1);
    }
  }
  img:nth-child(3){
    width: 151px;
  }
}
.succnewtime{
    width: calc(100% - 100px);
    margin: 200px auto 0;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 19px 0 30px;
    position: relative;
    img{
        width: 219px;
        position: absolute;
        top:-58px;
        left: calc(50% - 109px);
    }
    p{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444; 
        margin: 20px 0 24px;
        span{
            color: rgba(255, 39, 39, 1);
        }
    }
    div{
        width: 172px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin: 0 auto;
        background: url("https://ppyos.xijiuyou.com/202212/ppy-red-btn-img.png") no-repeat;
        background-size: 100%;
    }
}
</style>